<ion-item *ngFor="let field of ticketTypeInputFieldsFlat; trackBy: ticketTypeFieldsTrackBy" class="align-top">
  <ion-label slot="start" text-wrap>
    <p>{{ field.label }}</p>
  </ion-label>
  <ion-chip
    *ngIf="field.type === 'single-select'"
    mode="ios"
    class="select-chip select-outline"
    [outline]="true"
    (click)="showTicketTypeFieldOptions(field)"
    [disabled]="disabled"
  >
    <ion-label>
      {{ getTicketTypeFieldLabel(field, ticketTypeFields[field.id]) ?? 'None' }}
      <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
    </ion-label>
  </ion-chip>

  <ion-label text-wrap style="margin: 0" *ngIf="field.type === 'multi-select'">
    <ion-chip
      mode="ios"
      color="primary"
      class="chip tag-chip"
      *ngFor="let value of ticketTypeFields[field.id]"
      (click)="removeTicketTypeField(field, value)"
      [disabled]="disabled"
    >
      <ion-label> {{ getTicketTypeFieldLabel(field, value) }} </ion-label>
    </ion-chip>
    <ion-chip
      mode="ios"
      [outline]="true"
      class="chip"
      (click)="showTicketTypeFieldOptions(field)"
      [disabled]="disabled"
    >
      <ion-label>
        <span>Add...</span>
      </ion-label>
    </ion-chip>
  </ion-label>

  <ion-textarea
    *ngIf="field.type === 'long-answer'"
    [(ngModel)]="ticketTypeFields[field.id]"
    autoGrow="true"
    (ionBlur)="didBlurTicketTypeField(field)"
    placeholder="No value set..."
    style="margin: 0 4px"
    [disabled]="disabled"
  ></ion-textarea>

  <ion-input
    *ngIf="field.type === 'short-answer'"
    [(ngModel)]="ticketTypeFields[field.id]"
    (ionBlur)="didBlurTicketTypeField(field)"
    placeholder="No value set..."
    class="right-col-input-wrapper"
    [disabled]="disabled"
  ></ion-input>

  <ion-button
    color="dark"
    fill="clear"
    (click)="changeDateTicketTypeField(field)"
    *ngIf="field.type === 'date'"
    style="--padding-start: 6px"
    class="btn-small"
    [disabled]="disabled"
  >
    {{ ticketTypeFields[field.id] | date: 'M/d/yyyy' }}
  </ion-button>

  <ion-checkbox
    *ngIf="field.type === 'checkbox'"
    [(ngModel)]="ticketTypeFields[field.id]"
    (ionChange)="didBlurTicketTypeField(field)"
    class="right-col-input-wrapper"
    [disabled]="disabled"
  ></ion-checkbox>

  <ion-chip
    *ngIf="field.type === 'user-select'"
    mode="ios"
    class="select-chip select-outline"
    [outline]="true"
    (click)="showTicketTypeFieldOptions(field)"
    [disabled]="disabled"
  >
    <ion-label>
      {{ ticketTypeFieldLabels?.['user-select']?.[ticketTypeFields[field.id]] ?? 'None' }}
      <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
    </ion-label>
  </ion-chip>

  <ion-label text-wrap style="margin: 0" *ngIf="field.type === 'multi-user-select'">
    <ion-chip
      mode="ios"
      color="primary"
      class="chip tag-chip"
      *ngFor="let value of ticketTypeFields[field.id]"
      (click)="removeTicketTypeField(field, value)"
      [disabled]="disabled"
    >
      <ion-label> {{ ticketTypeFieldLabels?.['user-select']?.[value] ?? 'None' }} </ion-label>
    </ion-chip>
    <ion-chip
      mode="ios"
      [outline]="true"
      class="chip"
      (click)="showTicketTypeFieldOptions(field)"
      [disabled]="disabled"
    >
      <ion-label>
        <span>Add...</span>
      </ion-label>
    </ion-chip>
  </ion-label>
</ion-item>
