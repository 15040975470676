<ion-header>
  <div class="modal-header">
    <div class="modal-title">
      <h3>Create Conversation</h3>
    </div>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button fill="clear" shape="round" class="close-button" (click)="dismiss()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>
  <div style="padding: 0 16px 6px 16px">
    <ion-button fill="clear" color="dark" (click)="showPrivacyOptions()" class="btn-xs button-dark-border">
      <ion-icon
        [name]="type === 'email' ? 'mail-outline' : type === 'triage' ? 'lock-closed-outline' : 'logo-slack'"
        class="button-icon"
      ></ion-icon>
      {{ type === 'email' ? 'Email' : type === 'triage' ? 'Triage' : 'Slack' }}
    </ion-button>

    <ion-button
      *ngIf="linkType"
      color="dark"
      fill="clear"
      class="btn-xs button-dark-border"
      (click)="linkType = undefined"
      ngxTippy
      data-tippy-content="Click to remove linkage"
    >
      <ion-icon [name]="getLinkageIcon()" class="button-icon"></ion-icon>
      {{ getLinkageLabel() }}
    </ion-button>

    <ion-button fill="clear" color="dark" (click)="selectStatus()" class="btn-xs button-dark-border">
      <ion-icon *ngIf="status === 'open'" name="custom-open" class="status-label-needs-response button-icon"></ion-icon>
      <ion-icon
        *ngIf="status === 'in_progress'"
        name="custom-in-progress"
        class="status-label-in-progress button-icon"
      ></ion-icon>
      <ion-icon *ngIf="status === 'on_hold'" name="custom-on-hold" class="status-label-on-hold button-icon"></ion-icon>
      <ion-icon *ngIf="status === 'closed'" name="custom-closed" class="status-label-closed button-icon"></ion-icon>
      {{
        status === 'open'
          ? 'Needs Response'
          : status === 'in_progress'
            ? 'In Progress'
            : status === 'on_hold'
              ? 'On Hold'
              : 'Closed'
      }}
    </ion-button>

    <ion-button fill="clear" color="dark" (click)="selectPriority()" class="btn-xs button-dark-border">
      <ion-icon [name]="getPriorityIcon()" class="button-icon" *ngIf="getPriorityIcon()"></ion-icon>
      {{ getPriorityLabel() || 'Priority' }}
    </ion-button>

    <ion-button fill="clear" color="dark" (click)="selectProject()" class="btn-xs button-dark-border">
      {{ project?.name ?? 'None' }}
    </ion-button>
  </div>
</ion-header>

<ion-content>
  <ion-list class="form-list" lines="none">
    <ion-item class="align-top" *ngIf="type === 'email'">
      <ion-label slot="start">
        <p>Title</p>
      </ion-label>
      <ion-input placeholder="e.g. Password reset" [(ngModel)]="title" class="input-field"></ion-input>
    </ion-item>

    <ion-item class="align-top">
      <ion-label slot="start">
        <p>
          Issue details <ion-text *ngIf="type === 'email' || type === 'triage'" color="warning">(Required)</ion-text>
        </p>
      </ion-label>
      <ion-textarea
        #markdownInput
        placeholder="e.g. Customer needs help resetting password"
        [(ngModel)]="markdown"
        class="input-field"
        autoGrow="true"
        rows="3"
      >
      </ion-textarea>
    </ion-item>

    <ion-item class="align-top" *ngIf="type === 'email'">
      <ion-label slot="start">
        <p>Email Inbox <ion-text color="warning">(Required)</ion-text></p>
      </ion-label>
      <div style="width: 100%">
        <ion-spinner
          name="crescent"
          *ngIf="loadingInboxes"
          style="height: 33px; margin-top: 6px; margin-left: 5px"
        ></ion-spinner>
        <ion-chip
          mode="ios"
          class="select-chip select-outline"
          [outline]="true"
          (click)="showInboxOptions()"
          *ngIf="selectedEmailInbox"
        >
          <ion-label>
            <ion-icon name="mail" class="button-icon"></ion-icon>
            {{ selectedEmailInbox.email }}
            <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
          </ion-label>
        </ion-chip>
        <ion-button
          fill="clear"
          color="danger"
          (click)="showInboxOptions()"
          *ngIf="!loadingInboxes && emailInboxes && !emailInboxes.length"
        >
          <ion-icon name="alert-circle-outline" class="button-icon"></ion-icon>
          Configure email inbox first
        </ion-button>
      </div>
    </ion-item>

    <ion-item class="align-top" [hidden]="type !== 'triage'">
      <ion-label slot="start">
        <p>Triage Channel <ion-text color="warning">(Required)</ion-text></p>
      </ion-label>
      <app-channel-selector
        [channel]="triageChannel"
        (channelDidChange)="onTriageChannelChanged($event)"
        [defaultInternalOnly]="true"
      ></app-channel-selector>
    </ion-item>

    <ion-item class="align-top" *ngIf="type === 'slack'">
      <ion-label slot="start">
        <p>Post to</p>
      </ion-label>
      <div style="display: flex; height: 100%; align-items: center; margin: 0 2px">
        <div
          class="round-pill-tab round-pill-tab-left"
          [ngClass]="{
            'round-pill-tab-active': postingType === 'channel'
          }"
          (click)="postingType = 'channel'"
          style="flex-grow: 1"
        >
          Channel
        </div>
        <div
          class="round-pill-tab round-pill-tab-right"
          [ngClass]="{
            'round-pill-tab-active': postingType === 'direct'
          }"
          (click)="postingType = 'direct'"
          style="flex-grow: 1"
        >
          Direct Message
        </div>
      </div>
    </ion-item>

    <ion-item class="align-top" *ngIf="type === 'slack' && postingType === 'channel'">
      <ion-label slot="start">
        <p>Channel <ion-text color="warning">(Required)</ion-text></p>
      </ion-label>
      <app-channel-selector
        [channel]="channel"
        (channelDidChange)="channel = $event"
        [defaultInternalOnly]="true"
        [initialButtonText]="'Select Channel'"
      ></app-channel-selector>
    </ion-item>

    <ion-item class="align-top">
      <ion-label slot="start">
        <p>Ticket Type</p>
      </ion-label>
      <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="selectTicketType()">
        <ion-label>
          {{ ticketType?.name ?? 'None' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
        </ion-label>
      </ion-chip>
    </ion-item>

    <app-ticket-type-fields
      *ngIf="ticketType"
      [ticketType]="ticketType"
      [ticketTypeFields]="ticketTypeFields"
      (ticketTypeFieldsChange)="onTicketTypeFieldsChange($event)"
    ></app-ticket-type-fields>

    <ion-item class="align-top">
      <ion-label slot="start">
        <p>
          Submitter
          <ion-text *ngIf="type === 'email' || (type === 'slack' && postingType === 'direct')" color="warning"
            >(Required)</ion-text
          >
        </p>
      </ion-label>
      <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="selectSubmitter()">
        <ion-label>
          {{ submitter?.name ? submitter.name + ' (' + submitter.email + ')' : submitter?.email ?? 'None' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
        </ion-label>
      </ion-chip>
    </ion-item>

    <ion-item class="align-top" *ngIf="!authService.tenant.accountsEnabled">
      <ion-label slot="start">
        <p>Customer</p>
      </ion-label>
      <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="selectCustomer()">
        <ion-label>
          {{ customer?.name ?? 'None' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
        </ion-label>
      </ion-chip>
    </ion-item>

    <ion-item class="align-top" *ngIf="authService.tenant.accountsEnabled">
      <ion-label slot="start">
        <p>Account</p>
      </ion-label>
      <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="selectAccount()">
        <ion-label>
          {{ account?.name ?? 'None' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
        </ion-label>
      </ion-chip>
    </ion-item>

    <ion-item class="align-top">
      <ion-label slot="start">
        <p>Assignee</p>
      </ion-label>
      <div class="flexed-align-center" style="width: 100%">
        <ion-chip mode="ios" class="select-chip select-outline" [outline]="true" (click)="selectAssignee()">
          <ion-label>
            {{ assignee?.name ?? 'None' }}
            <ion-icon name="chevron-down-outline" class="icon-padded-left chevron-icon"></ion-icon>
          </ion-label>
        </ion-chip>
        <ion-button fill="clear" color="dark" (click)="assignToMe()" [hidden]="assignee?.id" class="btn-small">
          Assign to me
        </ion-button>
      </div>
    </ion-item>
  </ion-list>
</ion-content>

<ion-footer>
  <div style="padding: 15px">
    <ion-button (click)="submit()" [disabled]="isLoading || loadingInboxes" class="no-margin">
      Save and view
      <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
      <ion-icon name="arrow-forward-outline" class="icon-padded-left button-icon" [hidden]="isLoading"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
