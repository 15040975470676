import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import * as _ from 'lodash';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { Subject, Subscription, filter, distinctUntilChanged } from 'rxjs';

import {
  CUSTOM_FIELD_TYPE_MULTI_SELECT,
  CUSTOM_FIELD_TYPE_MULTI_USER_SELECT,
  CUSTOM_FIELD_TYPE_SINGLE_SELECT,
  CUSTOM_FIELD_TYPE_USER_SELECT,
} from 'src/app/constants';
import { evaluateConditions, init as initEvaluateConditions } from 'src/app/utils/custom-fields';
import { ApiService } from 'src/services/api.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

import { DatetimeModalComponent } from '../datetime-modal/datetime-modal.component';

/**
 * TicketTypeFieldsComponent
 *
 * This component displays and manages ticket type fields.
 * It emits changes only when not disabled and when changes are initiated by user interaction.
 */
@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgxTippyModule],
  selector: 'app-ticket-type-fields',
  templateUrl: './ticket-type-fields.component.html',
  styleUrls: ['./ticket-type-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTypeFieldsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ticketType: any;
  @Input() ticketTypeFields: any = {};
  @Input() disabled = false;
  @Output() ticketTypeFieldsChange = new EventEmitter<any>();

  ticketTypeInputFieldsFlat: any[] = [];
  ticketTypeFieldLabels: Record<string, Record<string, string>> = {};
  protected readonly Array = Array;

  // RxJS Subject for field updates
  private fieldUpdates = new Subject<{
    fieldId: string;
    value: any;
    source: 'user' | 'external';
  }>();

  // Flag to track update source
  private updateSource: 'user' | 'external' = 'user';

  // Last emitted fields object to prevent duplicate emissions
  private lastEmittedFields: any = null;

  // Subscription for cleanup
  private subscription = new Subscription();

  constructor(
    private searchablePopoverService: SearchablePopoverService,
    private modalCtrl: ModalController,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    // Setup the subscription to only process updates from user interaction
    const fieldUpdatesSub = this.fieldUpdates
      .pipe(
        filter((update) => !this.disabled && update.source === 'user'),
        distinctUntilChanged((prev, curr) => {
          // Use lodash's isEqual for more robust deep comparison
          return prev.fieldId === curr.fieldId && _.isEqual(prev.value, curr.value);
        }),
      )
      .subscribe((update) => {
        const newFields = {
          ...this.ticketTypeFields,
          [update.fieldId]: update.value,
        };

        // Only emit if value actually changed using lodash deep equality
        if (!this.lastEmittedFields || !_.isEqual(this.lastEmittedFields, newFields)) {
          this.lastEmittedFields = _.cloneDeep(newFields);

          // Emit the change to parent within NgZone
          this.ngZone.run(() => {
            this.ticketTypeFieldsChange.emit(newFields);
          });
        }
      });

    this.subscription.add(fieldUpdatesSub);
  }

  async ngOnInit() {
    await initEvaluateConditions();

    // Perform initial field setup without emitting changes
    this.updateSource = 'external';
    await this.updateFields();
    this.cdr.detectChanges();
    this.updateSource = 'user';
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['ticketType'] || changes['ticketTypeFields']) {
      // Mark updates as external during processing of input changes
      this.updateSource = 'external';
      try {
        // Process fields without emitting changes
        await this.updateFields();
        this.cdr.detectChanges();
      } finally {
        // Reset to user mode after processing
        this.updateSource = 'user';
      }
    }
  }

  ngOnDestroy() {
    // Clean up all subscriptions
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private async updateFields() {
    this.getTicketTypeFieldsFlat();
    await this.getTicketFieldFieldLabels();
  }

  async getTicketFieldFieldLabels() {
    const userSelectFields = (this.ticketType?.fields ?? [])
      .filter((f: any) => [CUSTOM_FIELD_TYPE_USER_SELECT, CUSTOM_FIELD_TYPE_MULTI_USER_SELECT].includes(f.type))
      .flatMap((f: any) => {
        const result = this.ticketTypeFields?.[f.id];
        return Array.isArray(result) ? result : [result];
      })
      .filter((v: any) => v);

    const externalSelectFields = (this.ticketType?.fields ?? [])
      .filter(
        (f: any) =>
          [CUSTOM_FIELD_TYPE_SINGLE_SELECT, CUSTOM_FIELD_TYPE_MULTI_SELECT].includes(f.type) && f.options != null,
      )
      .flatMap((f: any) => {
        const result = this.ticketTypeFields?.[f.id];
        const resultArray = Array.isArray(result) ? result : [result];
        return resultArray.map((v: any) => `${f.externalSelect}:${v}`);
      });

    if (userSelectFields.length || externalSelectFields.length) {
      const lookupValues: any = await this.apiService.postPromise('/saved-filters/get-lookup-values', {
        assigneeId: userSelectFields,
        externalSelectValue: externalSelectFields,
      });

      this.ticketTypeFieldLabels[CUSTOM_FIELD_TYPE_USER_SELECT] = lookupValues.assigneeId;
      this.ticketTypeFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] = lookupValues.externalSelectValue;
    }
  }

  getTicketTypeFieldsFlat() {
    if (!this.ticketType?.fields) {
      this.ticketTypeInputFieldsFlat = [];
      return;
    }

    const visibilityMap = this.ticketType.fields.reduce((acc: any, f: any) => {
      acc[f.id] = false;
      return acc;
    }, {});

    this.ticketTypeInputFieldsFlat = this.ticketType.fields.filter((f: any) => {
      const isVisible = evaluateConditions(
        {
          conversation: {
            ticketTypeFields: Object.fromEntries(
              Object.entries(this.ticketTypeFields).filter(([id]: [string, any]) => visibilityMap[id]),
            ),
          },
        },
        f.conditions,
      );

      visibilityMap[f.id] = isVisible;

      return isVisible;
    });
  }

  /**
   * All interactive methods below will just check if component is disabled
   * before doing anything
   */

  async showTicketTypeFieldOptions(field: any) {
    if (this.disabled) {
      return;
    }

    const isMultiSelect = [CUSTOM_FIELD_TYPE_MULTI_SELECT, CUSTOM_FIELD_TYPE_MULTI_USER_SELECT].includes(field.type);
    const isUserSelect = [CUSTOM_FIELD_TYPE_USER_SELECT, CUSTOM_FIELD_TYPE_MULTI_USER_SELECT].includes(field.type);
    const onSaveCallback = (id: string, label: string) => {
      let value: string | string[] | null = null;

      if (isMultiSelect) {
        value = Array.from(new Set<string>(this.ticketTypeFields?.[field.id] ?? []).add(id));
      } else {
        if (id !== this.ticketTypeFields?.[field.id]) {
          value = id;
        }
      }

      // Update
      this.handleUpdate(field.id, value);

      if (field.externalSelect != null) {
        this.ticketTypeFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] = {
          ...(this.ticketTypeFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] ?? {}),
          [`${field.externalSelect}:${value}`]: label,
        };
      }
    };

    if (isUserSelect) {
      this.searchablePopoverService.users({
        event,
        selectedUserId: this.ticketTypeFields?.[field.id] ?? null,
        callback: onSaveCallback,
      });
    } else {
      this.searchablePopoverService.customFieldOptions({
        event,
        field,
        callback: onSaveCallback,
        context: { ticketTypeFields: this.ticketTypeFields },
      });
    }
  }

  async changeDateTicketTypeField(field: any) {
    if (this.disabled) {
      return;
    }

    const datePickerModal = await this.modalCtrl.create({
      component: DatetimeModalComponent,
      cssClass: 'datetime-modal',
      componentProps: {
        selected: this.ticketTypeFields[field.id],
        title: 'Select date',
      },
    });

    await datePickerModal.present();

    datePickerModal.onDidDismiss().then((data) => {
      const result = data?.data?.value;

      if (result && result !== 'Invalid date') {
        this.handleUpdate(field.id, result);
      }
    });
  }

  removeTicketTypeField(field: any, value: string) {
    if (this.disabled) {
      return;
    }

    this.handleUpdate(
      field.id,
      this.ticketTypeFields[field.id].filter((v: string) => v !== value),
    );
  }

  didBlurTicketTypeField(field: any) {
    if (this.disabled) {
      return;
    }

    this.handleUpdate(field.id, this.ticketTypeFields[field.id]);
  }

  /**
   * Simple handleUpdate method - it uses the disabled flag to
   * determine whether updates should be emitted
   */
  private handleUpdate(fieldId: string, value: any) {
    if (this.disabled) {
      return;
    }

    // Create new fields object
    const newFields = {
      ...this.ticketTypeFields,
      [fieldId]: value,
    };

    // Update internal state
    this.ticketTypeFields = newFields;

    // Update UI
    this.getTicketTypeFieldsFlat();
    this.cdr.detectChanges();

    // Push to our RxJS stream with source info
    this.fieldUpdates.next({
      fieldId,
      value,
      source: this.updateSource,
    });
  }

  onInputChange(field: any, event: any) {
    if (this.disabled) {
      return;
    }

    // Extract the value from the DOM event
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    this.handleUpdate(field.id, value);
  }

  getTicketTypeFieldLabel(field: any, value: string) {
    if (field.externalSelect != null) {
      return this.ticketTypeFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT]?.[`${field.externalSelect}:${value}`] ?? value;
    }
    return value;
  }

  ticketTypeFieldsTrackBy(field: any) {
    return field.id;
  }
}
